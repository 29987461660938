<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card :data-background-color="getTheme">
        <template slot="header">
          <router-link :to="'/users'">
            <md-icon>group</md-icon>
          </router-link>
        </template>

        <template slot="content">
          <p class="category">{{ $t("dashboard.users") }}</p>
          <h3 class="title">{{ usersQuantity }}</h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>groups</md-icon>
            {{ $t("dashboard.inOrganization") }}
          </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card :data-background-color="getTheme">
        <template slot="header">
          <router-link :to="'/workers'">
            <md-icon>assignment_ind</md-icon>
          </router-link>
        </template>

        <template slot="content">
          <p class="category">{{ $t("dashboard.workers") }}</p>
          <h3 class="title">
            {{ workersQuantity }}
          </h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>groups</md-icon>
            {{ $t("dashboard.inOrganization") }}
          </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card :data-background-color="getTheme">
        <template slot="header">
          <router-link :to="'/devices'">
            <md-icon>device_hub</md-icon>
          </router-link>
        </template>

        <template slot="content">
          <p class="category">{{ $t("dashboard.devices") }}</p>
          <h3 class="title">{{ devicesQuantity }}</h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>groups</md-icon>
            {{ $t("dashboard.inOrganization") }}
          </div>
        </template>
      </stats-card>
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25">
      <stats-card data-background-color="orange">
        <template slot="header">
          <router-link :to="'/notifications'">
            <md-icon>notifications_active</md-icon>
          </router-link>
        </template>

        <template slot="content">
          <p class="category">
            {{ $t("dashboard.notifications") }}
          </p>
          <h3 class="title">{{ notificationsQuantity }}</h3>
        </template>

        <template slot="footer">
          <div class="stats">
            <md-icon>visibility</md-icon>
            {{ $t("dashboard.haventSee") }}
          </div>
        </template>
      </stats-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { StatsCard } from "../";
export default {
  name: "dashboard-summary",
  data() {
    return {
      usersQuantity: null,
      workersQuantity: null,
      devicesQuantity: null,
      notificationsQuantity: null,
    };
  },
  components: {
    StatsCard,
  },
  methods: {
    getDashboardSummary() {
      this.$store.dispatch("summary/getDashboardSummary").then((data) => {
        this.usersQuantity = data.usersQuantity;
        this.workersQuantity = data.workersQuantity;
        this.devicesQuantity = data.devicesQuantity;
        this.notificationsQuantity = data.notificationsQuantity;
      });
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  mounted() {
    this.getDashboardSummary();
  },
};
</script>
