<template>
  <div>
    <v-card
      elevation="5"
      class="rounded-lg"
      :class="`sparkline-card-${w}x${h}`"
      @click="toMonitoring"
    >
      <h5 class="text-center">
        {{ deviceSlot.layoutConfiguration.title }}
        {{ deviceSlot.layoutConfiguration.symbol }}
      </h5>
      <v-sparkline
        :value="value"
        :color="'#0085D6'"
        :smooth="radius || false"
        :padding="padding"
        :line-width="width"
        :stroke-linecap="lineCap"
        :gradient-direction="gradientDirection"
        :type="type"
        :auto-line-width="true"
        auto-draw
        :label-size="15"
      >
        <template v-slot:label="item" class="item-label">
          {{ item.value }}
        </template></v-sparkline
      >
    </v-card>
  </div>
</template>

<script>
export default {
  name: "linechart",
  props: {
    deviceSlot: Object,
    from: Date,
    to: Date,
    h: Number,
    w: Number,
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    deviceSlot: function () {
      clearInterval(this.interval);
      this.setComponent();
    },
  },
  data() {
    return {
      value: [],
      width: 1,
      radius: 1,
      padding: 8,
      lineCap: "round",
      gradientDirection: "top",
      type: "trend",
    };
  },
  methods: {
    setComponent() {
      this.getSlotMeasurements();
    },
    getSlotMeasurements() {
      let from = this.from;
      let to = this.to;
      this.makeRequest(from, to);
    },
    makeRequest(from, to) {
      this.$store
        .dispatch("monitoring/getMeasurements", {
          deviceSlotId: this.deviceSlot.slots[0].id,
          from: from,
          to: to,
        })
        .then((data) => {
          let measurements = data.measurements;
          measurements.forEach((measurement) => {
            this.value.push(Number(measurement.calibrated_measurement));
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toMonitoring() {
      if (this.isClickable) {
        this.$router.push({
          name: "deviceMonitoringTab",
          params: { device: this.deviceSlot.slots[0].deviceId, tab: 1 },
        });
      }
    },
  },
  mounted() {
    this.setComponent();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/displayers/sparkline.sass'
</style>