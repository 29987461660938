<template>
  <div>
    <div
      class="
        row
        d-flex
        justify-content-center
        spinner-container
        align-items-center
      "
      v-if="!dashboardSelected && dashboards.length > 0"
    >
      <breeding-rhombus-spinner
        :animation-duration="1500"
        :size="50"
        color="primary"
      />
    </div>
    <md-card v-if="dashboardSelected">
      <md-card-header
        :data-background-color="getTheme"
        class="row d-flex justify-content-between align-items-center"
      >
        <div class="col-6">
          <h4 class="title" v-if="dashboardSelected">
            {{
              dashboardSelected != null
                ? dashboardSelected.name
                : dashboards[0].name
            }}
          </h4>
        </div>
        <div class="col-5">
          <v-select
            v-model="dashboardSelected"
            :items="dashboards"
            item-text="name"
            item-value="id"
            label="Organization Displayers"
            persistent-hint
            return-object
            dark
            :disabled="dashboardEdit"
          ></v-select>
        </div>
        <div
          class="col-1"
          v-if="$store.getters.getPermission('dashboards_edit')"
        >
          <v-btn class="mx-2" fab small @click="dashboardEdit = !dashboardEdit">
            <v-icon color="primary" v-if="!dashboardEdit">
              mdi-pencil-outline
            </v-icon>
            <v-icon color="primary" v-if="dashboardEdit">
              mdi-pencil-lock-outline
            </v-icon>
          </v-btn>
        </div>
      </md-card-header>
      <md-card-content>
        <dynamic-dashboard
          :editable="dashboardEdit"
          :dashboardId="
            dashboardSelected != null ? dashboardSelected.id : dashboards[0].id
          "
          @editable="dashboardEdit = !dashboardEdit"
          v-if="dashboardSelected"
        ></dynamic-dashboard>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import DynamicDashboard from "@/components/DynamicDashboard/DynamicDashboard";
import { BreedingRhombusSpinner } from "epic-spinners";
import { mapGetters } from "vuex";

export default {
  components: {
    DynamicDashboard,
    BreedingRhombusSpinner,
  },
  data() {
    return {
      dashboardEdit: false,
      dashboards: [],
      dashboardSelected: null,
    };
  },
  methods: {
    getDashboards() {
      this.$store
        .dispatch("dashboard/getDashboards")
        .then((data) => {
          this.dashboards = data;
          this.initSelect();
        })
        .catch((err) => console.error(err));
    },
    initSelect() {
      if (this.dashboards) this.dashboardSelected = this.dashboards[0];
    },
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
  mounted() {
    this.getDashboards();
  },
};
</script>

<style>
</style>