<template>
  <span
    class="copy"
    v-clipboard="text"
    v-clipboard:success="clipboardSuccessHandler"
  >
    {{ text }}
    <svg style="width: 16px; height: 16px" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
      />
    </svg>
    <md-tooltip v-if="successMode" :md-active="true">Copied to clipboard</md-tooltip>
  </span>
</template>
<script>
export default {
  props: {
    text: { required: true },
  },
  data: () => ({
      successMode: false,
  }),
  methods: {
    clipboardSuccessHandler() {
      this.successMode = true;
      setTimeout(() => {this.successMode=false}, 1000);
    },
  },
};
</script>