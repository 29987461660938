<template>
  <div>
    <dashboard-summary />
    <load-modal v-if="loaderModal"></load-modal>

    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <complete-dynamic-dashboard></complete-dynamic-dashboard>
      </div>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <md-card-header :data-background-color="getTheme">
            <h4 class="title">{{ $t("dashboard.mapTitle") }}</h4>
          </md-card-header>
          <md-card-content>
            <big-map />
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardSummary from "@/components/Dashboard/DashboardSummary";
import BigMap from "@/components/Maps/BigMap";
import CompleteDynamicDashboard from "@/components/DynamicDashboard/CompleteDynamicDashboard";
import LoadModal from "@/components/UI/LoadModal";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    DashboardSummary,
    BigMap,
    CompleteDynamicDashboard,
    LoadModal,
  },
  data() {
    return {
      dailySalesChart: {
        data: {
          labels: ["M", "T", "W", "T", "F", "S", "S"],
          series: [[12, 17, 7, 17, 23, 18, 38]],
        },
        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      dataCompletedTasksChart: {
        data: {
          labels: ["12am", "3pm", "6pm", "9pm", "12pm", "3am", "6am", "9am"],
          series: [[230, 750, 450, 300, 280, 240, 200, 190]],
        },

        options: {
          lineSmooth: this.$Chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      emailsSubscriptionChart: {
        data: {
          labels: [
            "Ja",
            "Fe",
            "Ma",
            "Ap",
            "Mai",
            "Ju",
            "Jul",
            "Au",
            "Se",
            "Oc",
            "No",
            "De",
          ],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
  watch: {},
  computed: {
    ...mapState(["loaderModal"]),
    ...mapGetters(["getTheme"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
  mounted() {},
};
</script>
