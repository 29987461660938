<template>
  <div>
    <v-card
      elevation="5"
      class="rounded-lg"
      :class="`signal-card-${w}x${h}`"
      @click="toMonitoring"
    >
      <h5 class="initials" v-if="w == 1">SNL</h5>
      <h4
        v-text="deviceSlot.layoutConfiguration.title"
        v-if="(w == 2 || w == 3) && h == 1"
      ></h4>
      <div class="displayer-header" v-if="w >= 2 && h >= 2">
        <h4 v-text="deviceSlot.layoutConfiguration.title"></h4>
      </div>
      <template v-if="timestamp">
        <div class="measurement">
          <template v-if="w == 1 && h <= 3">
            <v-icon color="primary" size="40" v-if="measurement <= 1">
              mdi-signal-off</v-icon
            >
            <v-icon color="primary" size="40" v-if="measurement === 2">
              mdi-signal-cellular-1</v-icon
            >
            <v-icon color="primary" size="40" v-if="measurement === 2">
              mdi-signal-cellular-2</v-icon
            >
            <v-icon color="primary" size="40" v-if="measurement === 2">
              mdi-signal-cellular-3</v-icon
            >
            <v-icon color="primary" size="40" v-if="measurement >= 5">
              mdi-signal</v-icon
            >
          </template>
          <template v-if="w == 2 && h == 1">
            <v-icon color="primary" size="40" v-if="measurement <= 1">
              mdi-signal-off</v-icon
            >
            <v-icon color="primary" size="40" v-if="measurement === 2">
              mdi-signal-cellular-1</v-icon
            >
            <v-icon color="primary" size="40" v-if="measurement === 2">
              mdi-signal-cellular-2</v-icon
            >
            <v-icon color="primary" size="40" v-if="measurement === 2">
              mdi-signal-cellular-3</v-icon
            >
            <v-icon color="primary" size="40" v-if="measurement >= 5">
              mdi-signal</v-icon
            >
          </template>
          <template v-if="w > 1 && w <= 3 && h == 2">
            <v-icon color="primary" size="80" v-if="measurement <= 1">
              mdi-signal-off</v-icon
            >
            <v-icon color="primary" size="80" v-if="measurement === 2">
              mdi-signal-cellular-1</v-icon
            >
            <v-icon color="primary" size="80" v-if="measurement === 2">
              mdi-signal-cellular-2</v-icon
            >
            <v-icon color="primary" size="80" v-if="measurement === 2">
              mdi-signal-cellular-3</v-icon
            >
            <v-icon color="primary" size="80" v-if="measurement >= 5">
              mdi-signal</v-icon
            >
          </template>
          <template v-if="w > 1 && w <= 3 && h == 3">
            <v-icon color="primary" size="100" v-if="measurement <= 1">
              mdi-signal-off</v-icon
            >
            <v-icon color="primary" size="100" v-if="measurement === 2">
              mdi-signal-cellular-1</v-icon
            >
            <v-icon color="primary" size="100" v-if="measurement === 2">
              mdi-signal-cellular-2</v-icon
            >
            <v-icon color="primary" size="100" v-if="measurement === 2">
              mdi-signal-cellular-3</v-icon
            >
            <v-icon color="primary" size="100" v-if="measurement >= 5">
              mdi-signal</v-icon
            >
          </template>
        </div>
        <p class="text-center timestamp" v-if="h > 1">{{ timestamp }}</p>
      </template>
      <template v-else>
        <p class="text-center no-data" v-if="h > 1">No data available</p>
        <p class="text-center no-data" v-else>N/D</p>
      </template>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "signal",
  props: {
    deviceSlot: Object,
    h: Number,
    w: Number,
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    deviceSlot: function () {
      this.measurement = null;
      clearInterval(this.interval);
      this.setComponent();
    },
  },
  data() {
    return {
      measurement: null,
      interval: null,
      timestamp: "",
    };
  },
  methods: {
    setComponent() {
      this.getSlotMeasurement();
      this.interval = setInterval(() => this.getSlotMeasurement(), 30000);
    },
    getSlotMeasurement() {
      this.$store
        .dispatch("monitoring/getSlotMeasurement", this.deviceSlot.slots[0].id)
        .then((data) => {
          if (data.measurement) {
            this.measurement = Number(data.measurement.calibrated_measurement);
            this.timestamp = data.measurement.timestamp;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toMonitoring() {
      if (this.isClickable) {
        this.$router.push({
          name: "deviceMonitoringTab",
          params: { device: this.deviceSlot.slots[0].deviceId, tab: 0 },
        });
      }
    },
  },
  mounted() {
    this.setComponent();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style scoped lang="sass">
@import '@/assets/sass/displayers/signal.sass'
</style>