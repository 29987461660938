<template>
  <canvas ref="myChartWrapper"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  name: "ChartWrapper",
  watch: {
    plotData: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.myChart.data.datasets = newVal;
          this.myChart.update();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.myChart = new Chart(this.$refs.myChartWrapper, {
      type: "line",
      data: {
        datasets: this.plotData,
      },

      options: {
        responsive: true,
        ...this.options,
      },
    });
  },
  data() {
    return {
      myChart: null,
    };
  },
  props: {
    plotData: {
      type: Array,
      required: false,
      default: () => [
        { x: "2016-12-25", y: 20 },
        { x: "2016-12-26", y: 10 },
      ],
    },
    options: {
      type: Object,
      required: false,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>