<template>
  <div class="row d-flex justify-content-around align-items-center mb-5 mt-5">
    <div class="w-30">
      <v-autocomplete
        v-model="displayerSelected"
        :items="organizationDisplayers"
        item-text="name"
        item-value="id"
        :label="$t('workspace.dashboard.dashboardForm.organizationDisplayers')"
        persistent-hint
        return-object
      >
        <template slot="selection" slot-scope="data">
          <template>
            <span
              >{{ data.item.name }} - {{ data.item.slots[0].deviceAlias }}</span
            >
          </template>
        </template>
        <template slot="item" slot-scope="data">
          <template>
            <span
              >{{ data.item.name }} - {{ data.item.slots[0].deviceAlias }}</span
            >
          </template>
        </template></v-autocomplete
      >
    </div>
    <displayer-preview
      :deviceSlot="displayerSelected"
      :h="2"
      :w="2"
      v-if="displayerSelected != -1"
      class="w-30"
    ></displayer-preview>
    <v-btn color="primary" dark elevation="2" class="w-20" @click="addItem"
      >Agregar</v-btn
    >
  </div>
</template>

<script>
import DisplayerPreview from "@/components/DynamicDashboard/DisplayerPreview/DisplayerPreview";

export default {
  name: "CompleteDisplayerPreview",
  components: {
    DisplayerPreview,
  },
  data() {
    return {
      displayerSelected: -1,
      organizationDisplayers: [],
      dashboard: null,
    };
  },
  mounted() {
    this.getOrganizationDisplayers();
  },
  methods: {
    addItem() {
      if (this.displayerSelected != -1) {
        this.$emit("addItem", this.displayerSelected);
      }
    },
    getOrganizationDisplayers() {
      this.$store
        .dispatch("displayers/getOrganizationDisplayers")
        .then((data) => {
          this.organizationDisplayers = data;
        });
    },
  },
};
</script>

<style>
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
</style>