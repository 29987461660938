<template>
  <div v-if="deviceSlot != 1">
    <signal
      class="preview-width"
      :deviceSlot="deviceSlot"
      :h="h"
      :w="w"
      v-if="deviceSlot.displayModeId == 7"
      :isClickable="false"
    ></signal>
    <number
      class="preview-width"
      :deviceSlot="deviceSlot"
      :h="h"
      :w="w"
      v-if="deviceSlot.displayModeId == 5 || deviceSlot.displayModeId == 4"
      :isClickable="false"
    ></number>
    <battery
      class="preview-width"
      :deviceSlot="deviceSlot"
      :h="h"
      :w="w"
      v-if="deviceSlot.displayModeId == 6"
      :isClickable="false"
    ></battery>
    <linechart
      class="preview-width"
      :from="dateFilter[0]"
      :to="dateFilter[1]"
      :deviceSlot="deviceSlot"
      :options="{ displayModeBar: false }"
      v-if="deviceSlot.displayModeId == 1 && h >= 4"
      :isClickable="false"
    />
    <spark-line
      class="preview-width"
      :from="dateFilter[0]"
      :to="dateFilter[1]"
      :deviceSlot="deviceSlot"
      :h="h"
      :w="w"
      v-if="deviceSlot.displayModeId == 1 && h < 4"
      :isClickable="false"
    ></spark-line>
  </div>
</template>

<script>
import Number from "@/components/DynamicDashboard/Displayers/Number";
import Battery from "@/components/DynamicDashboard/Displayers/Battery";
import Signal from "@/components/DynamicDashboard/Displayers/Signal";
import Linechart from "@/components/DynamicDashboard/Displayers/Linechart";
import SparkLine from "@/components/DynamicDashboard/Displayers/SparkLine";
export default {
  props: {
    deviceSlot: Object,
    h: Number,
    w: Number,
  },
  components: {
    Number,
    Battery,
    Signal,
    Linechart,
    SparkLine,
  },
  data() {
    return {
      dateFilter: [],
    };
  },
  methods: {
    set48HrsDefault() {
      let from = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
      let to = new Date();

      this.dateFilter = [from, to];
    },
  },
  mounted() {
    this.set48HrsDefault();
  },
};
</script>

<style>
.preview-width {
  width: 60%;
}
</style>