<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="load-modal-container">
            <breeding-rhombus-spinner
              :animation-duration="2000"
              :size="100"
              color="#23c577"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { BreedingRhombusSpinner } from "epic-spinners";
export default {
  components: {
    BreedingRhombusSpinner,
  },
};
</script>

<style scoped lang="sass">
@import '@/assets/sass/modals/loadModal.sass'
@import '@/assets/sass/modals/modal.sass'
</style>