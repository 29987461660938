<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
    style="height: 500px"
    @update:center="centerUpdate"
    @update:zoom="zoomUpdate"
    ref="map"
  >
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-layer-group ref="markers">
      <l-marker
        v-for="device in devices"
        :key="device.id"
        :lat-lng="device.position"
      >
        <l-popup>
          <div>
            <p>
              {{ device.alias }} <br />
              <copy-to-clipboard :text="device.identifier" /> <br />
              <router-link :to="`/devices/${device.id}/details`"
                >Ver más ...</router-link
              >
            </p>
          </div>
        </l-popup>
        <l-icon
          :iconSize="[60, 60]"
          :iconAnchor="[15, 50]"
          :popupAnchor="[4, -42]"
        >
          <icons-svg
            v-if="device.online == true"
            :id="device.icon.id"
            :color="device.icon.icon_color"
          ></icons-svg>
          <icons-svg
            v-if="device.online == false"
            :id="device.icon.id"
            color="#929292"
          ></icons-svg>
        </l-icon>
      </l-marker>
      <l-polygon
        v-for="geoZone in geoZones"
        :key="geoZone.name"
        :lat-lngs="geoZone.geofence"
        :color="geoZone.color"
        :fillColor="geoZone.color"
        :weight="2"
        :opacity="0.5"
      ></l-polygon>
    </l-layer-group>
  </l-map>
</template>
<script>
import L from "leaflet";
import CopyToClipboard from "../UI/CopyToClipboard";
import "leaflet-search/src/leaflet-search";
import { latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolygon,
  LIcon,
  LLayerGroup,
} from "vue2-leaflet";
import { Icon } from "leaflet";
import IconsSvg from "@/components/Icons/Icons";

import { mapGetters } from "vuex";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "big-map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolygon,
    LIcon,
    LLayerGroup,
    CopyToClipboard,
    IconsSvg,
  },
  computed: {
    ...mapGetters("user", ["user"]),
  },
  watch: {
    user: {
      handler() {
        if (this.user) this.getDevicesWithPosition();
      },
    },
  },
  data: () => ({
    devices: [],
    zoom: 12,
    center: latLng(-33.4335796, -70.5962481),
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    currentZoom: 11.5,
    currentCenter: latLng(-33.4335796, -70.5962481),
    mapOptions: {
      zoomSnap: 0.5,
    },
    geoZones: [],
  }),
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    getDevicesWithPosition() {
      this.$store.dispatch("device/getDevicesWithPosition").then((data) => {
        const devices = [];
        data.forEach((device) => {
          if (device.position) {
            device.position = latLng(
              device.position.latitude,
              device.position.longitude
            );
            devices.push(device);
            if (this.user.organization_id) {
              console.log("subscribe from big map");
              this.subscribe(device.identifier, "up", (message) => {
                this.onDeviceEventMapUp(message, device);
              });
              this.subscribe(device.identifier, "downtime", (message) => {
                this.onDeviceEventMapDowntime(message, device);
              });
            }
          }
        });
        this.devices = devices;
      });
    },
    getZones() {
      this.$store
        .dispatch("zone/getZones")
        .then((data) => {
          this.geoZones = data;
        })
        .catch((err) => console.error(err));
    },
    localData(text, callResponse) {
      let searchData = [];
      this.devices.forEach((device) => {
        device.pos = [device.position.lat, device.position.lng];
        device.search = `dev: ${device.identifier}`;
        searchData.push(device);
      });

      this.geoZones.forEach((geoZone) => {
        geoZone.pos = [geoZone.geofence[0][0], geoZone.geofence[0][1]];
        geoZone.search = `geo: ${geoZone.name}`;
        searchData.push(geoZone);
      });

      callResponse(searchData);
    },
    subscribe(deviceIdentifier, event, wsFunction) {
      this.$tgcWebsocket.subscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.${event}`,
        wsFunction
      );
    },
    unsubscribe(deviceIdentifier, event, wsFunction) {
      this.$tgcWebsocket.unsubscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.${event}`,
        wsFunction
      );
    },
    onDeviceEventMapUp(message, device) {
      device.online = true;
      if (message.position)
        device.position = latLng(
          message.position.latitude,
          message.position.longitude
        );
    },
    onDeviceEventMapDowntime(message, device) {
      device.online = false;
      if (message.position)
        device.position = latLng(
          message.position.latitude,
          message.position.longitude
        );
    },
  },
  mounted() {
    const map = this.$refs.map.mapObject;
    map.addControl(
      new L.Control.Search({
        sourceData: this.localData,
        propertyName: "search",
        propertyLoc: "pos",
        textPlaceholder: "Search",
        position: "topright",
        marker: null,
        zoom: 13,
        initial: false,
        buildTip: function (text) {
          let textSplit = text.split(":");
          text = textSplit[1];
          let type = textSplit[0];
          return `<span><b>${type}: </b> ${text}</span>`;
        },
      })
    );
    this.getDevicesWithPosition();
    this.getZones();
  },
  beforeDestroy() {
    this.devices.forEach((device) => {
      this.unsubscribe(device.identifier, "up", (message) => {
        this.onDeviceEventMapUp(message, device);
      });
      this.unsubscribe(device.identifier, "downtime", (message) => {
        this.onDeviceEventMapDowntime(message, device);
      });
    });
  },
};
</script>
