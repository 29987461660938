<template>
  <div>
    <v-card elevation="5" @click="toMonitoring">
      <Plotly
        :data="data"
        :layout="layout"
        :display-mode-bar="true"
        :watchShallow="watchShallow"
        :autoResize="autoResize"
        :displayModeBar="false"
        :static-plot="true"
      ></Plotly>
    </v-card>
  </div>
</template>

<script>
import { Plotly } from "vue-plotly";
import { exportCSVFile } from "../../../utils/jsonToCsv";
export default {
  name: "linechart",
  components: {
    Plotly,
  },
  props: {
    deviceSlot: Object,
    from: Date,
    to: Date,
    isClickable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    deviceSlot: function () {
      this.data = [
        {
          x: [],
          y: [],
          type: "scatter",
        },
      ];
      clearInterval(this.interval);
      this.setComponent();
    },
    from: function () {
      if (this.timeoutBetweenRequests <= 0) {
        this.timeoutBetweenRequests = 1000;
        this.requestWithDelay();
      }
    },
    to: function () {
      if (this.timeoutBetweenRequests <= 0) {
        this.timeoutBetweenRequests = 1000;
        this.requestWithDelay();
      }
    },
  },
  data() {
    return {
      interval: null,
      layout: {
        title: {
          text: "",
        },
        height: 330,
        margin: {
          l: 35,
          r: 30,
          t: 35,
          b: 35,
        },
      },
      watchShallow: false,
      autoResize: true,
      data: [
        {
          x: [],
          y: [],
          type: "scatter",
        },
      ],
      timeoutBetweenRequests: 0,
    };
  },
  methods: {
    setComponent() {
      this.getSlotMeasurements();
      this.interval = setInterval(() => this.getSlotMeasurements(), 30000);
    },
    getSlotMeasurements() {
      let from = this.from;
      let to = this.to;
      if (this.data[0].x.length > 0) {
        if (!to) {
          from = this.data[0].x[this.data[0].x.length - 1];
          this.makeRequest(from, to);
        }
      } else {
        this.makeRequest(from, to);
      }
    },
    makeRequest(from, to) {
      this.$store
        .dispatch("monitoring/getMeasurements", {
          deviceSlotId: this.deviceSlot.slots[0].id,
          from: from,
          to: to,
        })
        .then((data) => {
          let measurements = data.measurements;
          measurements.forEach((measurement) => {
            this.data[0].y.push(Number(measurement.calibrated_measurement));
            this.data[0].x.push(measurement.timestamp);
          });
          if (measurements.length > 0) {
            this.data[0].y = this.data[0].y.slice();
            this.data[0].x = this.data[0].x.slice();
          }
          this.$emit("loaded");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    requestWithDelay() {
      setTimeout(() => {
        if (this.timeoutBetweenRequests <= 0) {
          this.timeoutBetweenRequests = 0;
          this.data = [
            {
              x: [],
              y: [],
              type: "scatter",
            },
          ];
          clearInterval(this.interval);
          this.setComponent();
        } else {
          this.timeoutBetweenRequests = this.timeoutBetweenRequests - 100;
          this.requestWithDelay();
        }
      }, 100);
    },
    exportCSV(e) {
      e.preventDefault();
      const headers = {
        x: "value",
        y: "timestamp",
      };
      const data = this.data[0].x.map((value, index) => {
        return { y: this.data[0].y[index], x: value };
      });
      exportCSVFile(headers, data, "Data");
    },
    setLayoutTitle() {
      this.layout.title.text = this.deviceSlot.layoutConfiguration.title;
    },
    toMonitoring() {
      if (this.isClickable) {
        this.$router.push({
          name: "deviceMonitoringTab",
          params: { device: this.deviceSlot.slots[0].deviceId, tab: 1 },
        });
      }
    },
  },
  mounted() {
    this.setComponent();
    this.setLayoutTitle();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>