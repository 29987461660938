<template>
  <v-sheet elevation="3" class="pa-3 rounded-lg slot-graph">
    <v-skeleton-loader v-if="showSkeleton" type="list-item, image, table-row" />
    <template v-else>
      <chart-wrapper v-if="plotData" :plotData="plotData" :options="options" />
    </template>
  </v-sheet>
</template>

<script>
import ChartWrapper from "./ChartWrapper.vue";
import "chartjs-adapter-date-fns";
import { es } from "date-fns/locale";
import { mapGetters } from "vuex";

export default {
  components: { ChartWrapper },
  name: "SlotGraph",
  data() {
    return {
      plotData: null,
      graphPoints: 20,
      showSkeleton: true,
      options: {
        plugins: {
          title: {
            display: true,
          },
          legend: {
            display: false,
          },
        },
        locale: "es",
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              callback: (value, index, values) => {
                return `${value} ${this.deviceSlot.layoutConfiguration.symbol}`;
              },
            },
          },
          x: {
            parsing: false,
            adapter: {
              date: {
                locale: es,
              },
            },
            type: "time",
            ticks: {
              // autoSkip: true,
              maxTicksLimit: 6.9,
              source: "auto",
              maxRotation: 15,
              minRotation: 15,
            },
            time: {
              tooltipFormat: "dd/MM/yyyy HH:mm",
              // unit: "hour",
              // stepSize: 30,
              displayFormats: {
                day: "dd/MM/yyy HH:mm:ss",
                hour: "dd/MM/yyy HH:mm:ss",
                minute: "dd/MM/yyy HH:mm:ss",
              },
            },
          },
        },
      },
    };
  },
  props: {
    deviceSlot: {
      type: Object,
      required: false,
    },
    interval: {
      type: Object,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
  },
  methods: {
    getMeasurements() {
      this.showSkeleton = true;
      if (this.interval) {
        const { start, end } = this.interval;
        this.$store
          .dispatch("monitoring/getMeasurements", {
            deviceSlotId: this.slotId,
            from: start,
            to: end,
          })
          .then((data) => {
            let dataPivot = data.measurements.map((measurement) => {
              return {
                x: measurement.timestamp,
                y: measurement.calibrated_measurement,
              };
            });
            dataPivot = dataPivot.slice(-this.graphPoints);
            this.plotData = [
              {
                label: this.deviceSlot.name,
                borderColor: this.color ?? "#23c577",
                data: dataPivot,
                borderWidth: 1,
                pointBorderWidth: 2,
                pointRadius: 2,
              },
            ];
            this.options.plugins.title.text = this.deviceSlot.name;
            this.showSkeleton = false;
            this.subscribe(this.deviceIdentifier);
          });
      }
    },
    subscribe(deviceIdentifier) {
      console.log("subscrito");
      this.$tgcWebsocket.subscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.up`,
        this.onDeviceEventGraph
      );
    },
    unsubscribe(deviceIdentifier) {
      this.$tgcWebsocket.unsubscribe(
        `organization.${this.user.organization_id}.device.${deviceIdentifier}.event.up`,
        this.onDeviceEventGraph
      );
    },
    onDeviceEventGraph(message) {
      message.body.slots.forEach((slot) => {
        if (slot.id === this.slotId) {
          this.plotData[0].data.push({
            x: new Date(message.body.custom_timestamp),
            y: message.body.slots[0].measurement.measurement,
          });
          this.plotData[0].data = this.plotData[0].data.slice(
            -this.graphPoints
          );
        }
      });
    },
    formatDate(timestamp) {
      let tempDate = new Date(timestamp);
      console.log(tempDate);
      let date = tempDate.toLocaleDateString("es-es");
      let time = tempDate.toLocaleTimeString("es-es");

      return `${date} ${time}`;
    },
  },
  computed: {
    ...mapGetters("user", ["user"]),
    slotId() {
      return this.deviceSlot.slots[0].id;
    },
    deviceIdentifier() {
      return this.deviceSlot.slots[0].deviceIdentifier;
    },
  },
  mounted() {
    this.getMeasurements();
  },
  beforeDestroy() {
    this.unsubscribe(this.deviceIdentifier);
  },
};
</script>

<style lang="sass" scoped>
.slot-graph
  height: 100%!important
  display: flex
  justify-content: center
  align-items: center
</style>